import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleRight, faMapMarkerAlt, faEnvelope, faPhone, faClock, faShare } from '@fortawesome/free-solid-svg-icons';
import { faYelp } from '@fortawesome/free-brands-svg-icons';
import logo from '../logo.svg';

const Footer = () => {
  return (
    <div className="container-fluid footer py-5 wow fadeIn" data-wow-delay="0.2s">
      <div className="container py-5">
        <div className="row g-5">
          <div className="col-md-6 col-lg-6 col-xl-3">
            <div className="footer-item d-flex flex-column align-items-start"> {/* Added align-items-start for left alignment */}
              <h4 className="text-white mb-4">
                <img src={logo} alt="Company Logo" className="me-3" style={{ width: '40px', height: '40px' }} />Get On PT
              </h4>
              <p>Physical Therapists in Manhattan, NY, helping new Moms live life without pain medication after postpartum. Visit us today!</p>
              <div className="d-flex">
                <FontAwesomeIcon icon={faShare} className="fa-2x text-white me-2" />
                <a className="btn btn-primary text-white rounded-circle d-flex justify-content-center align-items-center mx-1" href="https://www.yelp.com/biz/get-on-physical-therapy-new-york?osq=geton+physical+therapy" target="_blank" rel="noopener noreferrer" style={{ width: '40px', height: '40px' }}>
                  <FontAwesomeIcon icon={faYelp} />
                </a>
                <a className="btn btn-primary text-white rounded-circle d-flex justify-content-center align-items-center mx-1" href="mailto:getonpt0111@gmail.com" style={{ width: '40px', height: '40px' }}>
                  <FontAwesomeIcon icon={faEnvelope} />
                </a>
              </div>
            </div>
          </div>

          <div className="col-md-6 col-lg-6 col-xl-3">
            <div className="footer-item d-flex flex-column">
              <h4 className="mb-4 text-white">Quick Links</h4>
              <Link to="/" className="footer-link"><FontAwesomeIcon icon={faAngleRight} className="me-2" /> Home</Link>
              <Link to="/services" className="footer-link"><FontAwesomeIcon icon={faAngleRight} className="me-2" /> Services</Link>
              <Link to="/about" className="footer-link"><FontAwesomeIcon icon={faAngleRight} className="me-2" /> About</Link>
              {/* <Link to="/contact" className="footer-link"><FontAwesomeIcon icon={faAngleRight} className="me-2" /> Contact Us</Link> */}
              <Link to="/contact" className="footer-link"><FontAwesomeIcon icon={faAngleRight} className="me-2" /> Book Appointment</Link>
            </div>
          </div>

          <div className="col-md-6 col-lg-6 col-xl-3">
            <div className="footer-item d-flex flex-column">
              <h4 className="mb-4 text-white">Our Services</h4>
              <Link to="/services/physical-therapy" className="footer-link"><FontAwesomeIcon icon={faAngleRight} className="me-2" /> Physical Therapy</Link>
              <Link to="/services/" className="footer-link"><FontAwesomeIcon icon={faAngleRight} className="me-2" /> Massage Therapy</Link>
              <Link to="/services/" className="footer-link"><FontAwesomeIcon icon={faAngleRight} className="me-2" /> Balance Therapy</Link>
              <Link to="/services/" className="footer-link"><FontAwesomeIcon icon={faAngleRight} className="me-2" />Work Injuries</Link>
            </div>
          </div>

          <div className="col-md-6 col-lg-6 col-xl-3">
            <div className="footer-item d-flex flex-column">
              <h4 className="mb-4 text-white">Contact Info</h4>
              <a href="https://maps.app.goo.gl/CjECzB5ax21b7n4T8" target="_blank" rel="noopener noreferrer"><FontAwesomeIcon icon={faMapMarkerAlt} className="me-2" /> 122 W 26th St #500, New York</a>
              <a href="mailto:getonpt0111@gmail.com"><FontAwesomeIcon icon={faEnvelope} className="me-2" /> getonpt0111@gmail.com</a>
              <a href="tel:+19083423269"><FontAwesomeIcon icon={faPhone} className="me-2" /> 908.342.3269</a>
              <p><FontAwesomeIcon icon={faClock} className="me-2" /> Mon - Sun: 8:30 AM - 4:30 PM</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
