import React from 'react';
import About1 from '../img/about-1.jpg';  // Update the path according to your folder structure
import About2 from '../img/about-2.jpg';  // Update the path according to your folder structure
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import PageTransitionWrapper from './PageTransitionWrapper';


import { Analytics } from "@vercel/analytics/react";
import { SpeedInsights } from "@vercel/speed-insights/react";

const About = () => {
  return (
    <PageTransitionWrapper>
    <div className="container-fluid about bg-light py-5" id="aboutSection">

      <Analytics />
      <SpeedInsights />

      <div className="container py-5">
        <div className="row g-5 align-items-center">
          <div className="col-lg-5 wow fadeInLeft" data-wow-delay="0.2s">
            <div className="about-img pb-5 ps-5">
              <img src={About1} className="img-fluid rounded w-100" alt="About Us" style={{ objectFit: 'cover' }} />
              <div className="about-img-inner">
                <img src={About2} className="img-fluid rounded-circle w-100 h-100" alt="Our Mission" />
              </div>
              <div className="about-experience">6+ years experience</div>
            </div>
          </div>
          <div className="col-lg-7 wow fadeInRight" data-wow-delay="0.4s">
            <div className="section-title text-start mb-5">
              <h4 className="sub-title pe-3 mb-0">About Us</h4>
              <h1 className="display-3 mb-4">We are Ready to Help Improve Your Treatment.</h1>
              <p className="mb-4">Newly opened, our clinic sets a new standard in physical therapy. Dedicated to your unique path to recovery, we offer personalized care tailored to your specific needs.</p>
              <div className="mb-4">
                <p className="text-secondary">
                  <FontAwesomeIcon icon={faCheck} className="text-primary me-2" /> Personalized Pathways to Wellness
                </p>
                <p className="text-secondary">
                  <FontAwesomeIcon icon={faCheck} className="text-primary me-2" /> Innovative Care, Lasting Results
                </p>
                <p className="text-secondary">
                  <FontAwesomeIcon icon={faCheck} className="text-primary me-2" /> Compassion in Every Interaction
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </PageTransitionWrapper>
  );
};

export default About;
