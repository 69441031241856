import React from 'react';
// import Typed from 'typed.js'; // Commented out as we're not using Typed.js anymore
import Carousel1 from '../img/carousel-2.jpg'; // Update the path according to your folder structure
import { Link } from 'react-router-dom'; // Import Link for navigation

const Hero = () => {
  // Commenting out the Typed.js effect as per client's request
  /*
  const typedRef = useRef(null);

  useEffect(() => {
    const typedOptions = {
      strings: ["pain", "stress", "fatigue"],
      typeSpeed: 80,
      backSpeed: 80,
      backDelay: 4000,
      startDelay: 1000,
      loop: true,
      showCursor: true,
      contentType: 'html', // or null for plain text
    };

    const typed = new Typed(typedRef.current, typedOptions);

    return () => {
      typed.destroy();
    };
  }, []);
  */

  return (
    <div className="hero-section" style={{ backgroundImage: `url(${Carousel1})` }}>
      <div className="container">
        <div className="row align-items-center justify-content-center text-center">
          <div className="col-md-10">
            <h1>Physical Therapists in Manhattan, NY, helping new Moms live life without pain medication after postpartum.</h1>
            {/* <span ref={typedRef} className="typed-text"></span> */} {/* Commented out as we're not using Typed.js anymore */}
            {/* Add a little extra space before the button */}
            <div style={{ margin: '2rem 0' }}> {/* This creates more space between the headline and the button */}
              <Link to="/contact" className="btn btn-primary rounded-pill text-white py-2 px-4" style={{ fontWeight: 'bold' }}>Book Appointment</Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
