import React from 'react';
import { Helmet } from 'react-helmet';
import PageTransitionWrapper from '../PageTransitionWrapper'; // Adjust the import path as needed
import { Link } from 'react-router-dom'; // Import Link from react-router-dom


const WorkInjuries = () => {
  return (
    <PageTransitionWrapper>
      <div className="container py-5">
        <Helmet>
          <title>Work Injuries - Get On PhysicalTherapy</title>
          <meta name="description" content="Professional work injuries services to relieve stress and improve relaxation." />
        </Helmet>

        <div className="service-page">
          <h1 className="service-title">Work Injuries</h1>
          <p className="service-description">This is a placeholder for the Work Injuries service. Detailed content about the service will be added here.</p>
          {/* Additional content such as images, details about the service, benefits, etc., can be added here */}
        </div>
      </div>
    </PageTransitionWrapper>
  );
};

export default WorkInjuries;
