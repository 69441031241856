import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom'; // Import useLocation
import '../styles/styles.css';

const PageTransitionWrapper = ({ children }) => {
  const location = useLocation(); // Get current location
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    // Reset animation state on route change
    setIsActive(false);
    setTimeout(() => setIsActive(true), 50); // Slight delay to trigger re-animation
  }, [location]); // Depend on location

  return (
    <div className={`page-transition ${isActive ? 'slide-up-animation' : ''}`}>
      {children}
    </div>
  );
};

export default PageTransitionWrapper;
