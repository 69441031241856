import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'; // Import from 'react-router-dom' for navigation
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt, faPhoneAlt, faEnvelope, faBars } from '@fortawesome/free-solid-svg-icons';
import { faFacebookF, faYelp, faInstagram} from '@fortawesome/free-brands-svg-icons';

const Navbar = () => {
  const [isSticky, setIsSticky] = useState(false);
  const [isNavCollapsed, setIsNavCollapsed] = useState(true);

  useEffect(() => {
    const handleScroll = () => {
      const topbarHeight = 45;
      setIsSticky(window.scrollY > topbarHeight);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const toggleNav = () => setIsNavCollapsed(!isNavCollapsed);

  return (
    <>
      {/* Topbar Start */}
      <div className="container-fluid bg-dark px-5 d-none d-lg-block">
        <div className="row gx-0 align-items-center" style={{ height: '45px' }}>
          <div className="col-lg-8 text-center text-lg-start mb-lg-0">
            <div className="d-flex flex-wrap">
              <a href="https://maps.app.goo.gl/CjECzB5ax21b7n4T8" className="text-light me-4" target="_blank" rel="noopener noreferrer">
                <FontAwesomeIcon icon={faMapMarkerAlt} className="text-primary me-2" />122 W 26th St #500
              </a>
              <a href="tel:9083423269" className="text-light me-4 d-flex align-items-center" target="_blank">
                <FontAwesomeIcon icon={faPhoneAlt} style={{ transform: 'scaleX(-1)' }} className="text-primary me-2" />
                908.342.3269
              </a>
              <a href="mailto:getonpt0111@gmail.com" className="text-light me-0" target="_blank">
                <FontAwesomeIcon icon={faEnvelope} className="text-primary me-2" />getonpt0111@gmail.com
              </a>
            </div>
          </div>
          <div className="col-lg-4 text-center text-lg-end">
            <div className="d-flex align-items-center justify-content-end">
              {/* <a href="#" className="btn btn-light btn-square border rounded-circle nav-fill me-3 d-flex justify-content-center align-items-center" target="_blank">
                <FontAwesomeIcon icon={faFacebookF} />
              </a> */}
              {/* <a href="#" className="btn btn-light btn-square border rounded-circle nav-fill me-3 d-flex justify-content-center align-items-center" target="_blank">
                <FontAwesomeIcon icon={faInstagram} />
              </a> */}
              <a href="https://www.yelp.com/biz/get-on-physical-therapy-new-york?osq=geton+physical+therapy" className="btn btn-light btn-square border rounded-circle nav-fill me-3 d-flex justify-content-center align-items-center" target="_blank">
                <FontAwesomeIcon icon={faYelp} />
              </a>
              <a href="mailto:getonpt0111@gmail.com" className="btn btn-light btn-square border rounded-circle nav-fill me-0 d-flex justify-content-center align-items-center" target="_blank">
                <FontAwesomeIcon icon={faEnvelope} />
              </a>
            </div>
          </div>
        </div>
      </div>
      {/* Topbar End */}

      {/* Navbar Start */}
      <div className={`navbar-container ${isSticky ? 'fixed-top' : ''}`}>
        <nav className="navbar navbar-expand-lg navbar-light bg-white px-4 px-lg-5 py-3">
          <Link to="/" className="navbar-brand p-0">
            <h1 className="text-primary m-0">Get On PhysicalTherapy</h1>
          </Link>
          
          <button className="navbar-toggler" type="button" onClick={toggleNav}>
            <FontAwesomeIcon icon={faBars} />
          </button>

          <div className={`collapse navbar-collapse ${!isNavCollapsed ? 'show' : ''}`}>
            <div className="navbar-nav ms-auto py-0">
              <Link to="/" className="nav-item nav-link" style={{ fontWeight: 'bold' }}>Home</Link>
              <Link to="/services" className="nav-item nav-link" style={{ fontWeight: 'bold' }}>Services</Link>
              <Link to="/about" className="nav-item nav-link" style={{ fontWeight: 'bold' }}>About</Link>
              <Link to="/contact" className="nav-item nav-link" style={{ fontWeight: 'bold' }}>Contact Us</Link>
            </div>
            <Link to="/contact" className="btn btn-primary rounded-pill text-white py-2 px-4 ms-lg-4" style={{ fontWeight: 'bold' }}>Book Appointment</Link>
          </div>
        </nav>
      </div>
      {/* Navbar End */}
    </>
  );
};

export default Navbar;
