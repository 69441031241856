import React, { useState, useEffect, useRef } from 'react';
import './styles/styles.css';
import 'animate.css';
import './styles/bootstrap.min.css';
import Typed from 'typed.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp } from '@fortawesome/free-solid-svg-icons';
import { Routes, Route } from 'react-router-dom';
import Footer from './components/Footer';
import Navbar from './components/Navbar';
import Hero from './components/Hero';
import Feature from './components/Feature';
import Service from './components/Service'; // Ensure this is your updated Services component
import About from './components/About';
import ContactUs from './components/ContactUs';
import PageTransitionWrapper from './components/PageTransitionWrapper';

import BalanceTherapy from './components/ServicePages/BalanceTherapy';
import GaitTraining from './components/ServicePages/GaitTraining';
import MassageTherapy from './components/ServicePages/MassageTherapy';
import OngoingStrengthTraining from './components/ServicePages/OngoingStrengthTraining';
import PhysicalTherapy from './components/ServicePages/PhysicalTherapy';
import PostSurgicalTraining from './components/ServicePages/PostSurgicalTraining';
import SportsInjuries from './components/ServicePages/SportsInjuries';
import WorkInjuries from './components/ServicePages/WorkInjuries';

import { Analytics } from "@vercel/analytics/react";
import { SpeedInsights } from "@vercel/speed-insights/react";

function App() {
  const [isLoading, setIsLoading] = useState(true);
  const [isSticky, setIsSticky] = useState(false);
  const typedRef = useRef(null);
  const [showScroll, setShowScroll] = useState(false);

  useEffect(() => {
    const loadingTimeout = setTimeout(() => {
      setIsLoading(false);
    }, 1000);

    const handleScroll = () => {
      const topbarHeight = 45;
      setIsSticky(window.scrollY > topbarHeight);

      if (!showScroll && window.pageYOffset > 400) {
        setShowScroll(true);
      } else if (showScroll && window.pageYOffset <= 400) {
        setShowScroll(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    let typed;
    if (typedRef.current) {
      const typedOptions = {
        strings: ["pain", "stress", "fatigue"],
        typeSpeed: 80,
        backSpeed: 80,
        backDelay: 4000,
        startDelay: 1000,
        loop: true,
        showCursor: true,
        contentType: 'html',
      };
      typed = new Typed(typedRef.current, typedOptions);
    }

    return () => {
      clearTimeout(loadingTimeout);
      window.removeEventListener('scroll', handleScroll);
      if (typed) typed.destroy();
    };
  }, [showScroll]);

  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
      <div className={`App${isSticky ? ' sticky-nav' : ''}`}>

        <Analytics />
        <SpeedInsights />
        <Navbar />

        <div style={{ paddingTop: isSticky ? '60px' : '0px' }}>

        <Routes>
          <Route path="/" element={<PageTransitionWrapper><Hero /><Feature /></PageTransitionWrapper>} />
          <Route path="/services" element={<PageTransitionWrapper><Service /></PageTransitionWrapper>} />
          <Route path="/services/massage-therapy" element={<PageTransitionWrapper><MassageTherapy /></PageTransitionWrapper>} />
          <Route path="/services/physical-therapy" element={<PageTransitionWrapper><PhysicalTherapy /></PageTransitionWrapper>} />
          <Route path="/services/balance-therapy" element={<PageTransitionWrapper><BalanceTherapy /></PageTransitionWrapper>} />
          <Route path="/services/post-surgical-training" element={<PageTransitionWrapper><PostSurgicalTraining /></PageTransitionWrapper>} />
          <Route path="/services/gait-training" element={<PageTransitionWrapper><GaitTraining /></PageTransitionWrapper>} />
          <Route path="/services/work-injuries" element={<PageTransitionWrapper><WorkInjuries /></PageTransitionWrapper>} />
          <Route path="/services/sports-injuries" element={<PageTransitionWrapper><SportsInjuries /></PageTransitionWrapper>} />
          <Route path="/services/ongoing-strenght-training" element={<PageTransitionWrapper><OngoingStrengthTraining /></PageTransitionWrapper>} />
          <Route path="/about" element={<PageTransitionWrapper><About /></PageTransitionWrapper>} />
          <Route path="/contact" element={<PageTransitionWrapper><ContactUs /></PageTransitionWrapper>} />
        </Routes>

        </div>
        <Footer serviceSection="serviceSection" aboutSection="aboutSection" contactUsSection="contactUsSection" />
        {showScroll &&
          <button className="btn btn-primary btn-lg-circle back-to-top" onClick={scrollTop}>
            <FontAwesomeIcon icon={faArrowUp} />
          </button>
        }
      </div>
  );
}

export default App;
