import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import PageTransitionWrapper from '../PageTransitionWrapper';
import YourImage from '../../img/heroPT.jpg'; // Ensure this path is correct
import { Link } from 'react-router-dom';
import '../../styles/ServicePages/PhysicalTherapy.scss'; // Ensure this path is correct

// Importing images for each service
import AnkleImage from '../../img/services/ankle.jpeg';
import HipImage from '../../img/services/hip.jpeg';
import KneeImage from '../../img/services/knee.jpeg';
import BackImage from '../../img/services/lowerback.jpeg';
import NeckImage from '../../img/services/neck.jpeg';
import FootImage from '../../img/services/foot.jpeg';

const services = [
  { title: 'Ankle & Foot', image: AnkleImage },
  { title: 'Hip Care', image: HipImage },
  { title: 'Knee Rehabilitation', image: KneeImage },
  { title: 'Back & Spine', image: BackImage },
  { title: 'Neck Pain', image: NeckImage },
  { title: 'Foot Pain', image: FootImage },
];

const PhysicalTherapy = () => {
  return (
    <PageTransitionWrapper>
      <div className="hero-section" style={{ backgroundImage: `url(${YourImage})`, color: '#ffffff' }}>
        <div className="container">
          <div className="row align-items-center justify-content-center text-center">
            <div className="col-md-10">
              <h2 className="display-3 mb-4" style={{ color: '#ffffff' }}>Physical Therapy</h2>
              <Link to="/contact" className="btn btn-primary rounded-pill text-white py-2 px-4" style={{ fontWeight: 'bold' }}>Book Appointment</Link>
            </div>
          </div>
        </div>
      </div>

      <Container className="py-5 my-5" id="physicalTherapySection">
        <Row className="justify-content-center text-center mb-5">
          <Col lg={8}>
            <h2 className="mb-4">Holistic Physical Therapy Services</h2>
            <p>Our approach to physical therapy is to treat the individual as a whole, not just the symptoms. We aim to restore your functional capacity and get you back to the life you were meant to live.</p>
          </Col>
        </Row>

        {/* Wrapping services in a col-lg-8 to match the margin of the container above */}
        <Row className="justify-content-center">
          <Col lg={8}>
            <Row>
              {services.map((service, index) => (
                <Col md={6} lg={4} className="mb-4 text-center" key={index}>
                  <div className="service-icon-wrapper">
                    <img src={service.image} alt={service.title} className="service-icon" />
                  </div>
                  <h3 className="service-title">{service.title}</h3>
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
      </Container>
    </PageTransitionWrapper>
  );
};

export default PhysicalTherapy;
