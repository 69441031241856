import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserMd, faBriefcaseMedical, faBullseye, faFileMedicalAlt, faClinicMedical, faSmile, faClock, faHeartbeat } from '@fortawesome/free-solid-svg-icons';

const Feature = () => {
  return (
    <div className="container-fluid feature py-5">
      <div className="container py-5">
        <div className="section-title mb-5 wow fadeInUp" data-wow-delay="0.1s">
          <div className="sub-style">
            <h4 className="sub-title px-3 mb-0">Why Choose Us</h4>
          </div>
          <h1 className="display-3 mb-4">Why Choose Us? Get Your Lifestyle Back</h1>
        </div>

        <div className="row g-4 justify-content-center">
          {/* Feature items */}
          {/* Each feature item is represented by a div.col-md-6.col-lg-4.col-xl-3.wow.fadeInUp */}
          {/* For brevity, here's an example of one feature item: */}

          <div className="col-md-6 col-lg-4 col-xl-3 wow fadeInUp" data-wow-delay="0.1s">
            <div className="row-cols-1 feature-item p-4">
              <div className="col-12">
                <div className="feature-icon mb-4">
                  <div className="p-3 d-inline-flex bg-white rounded">
                    <FontAwesomeIcon icon={faUserMd} className="fa-4x text-primary" />
                  </div>
                </div>
                <div className="feature-content d-flex flex-column">
                  <h5 className="mb-4">Licensed Therapist</h5>
                  <p className="mb-0">Expert care from certified therapists ensures your journey to wellness is in skilled hands</p>
                </div>
              </div>
            </div>
          </div>
          

          <div className="col-md-6 col-lg-4 col-xl-3 wow fadeInUp" data-wow-delay="0.3s">
              <div className="row-cols-1 feature-item p-4">
                <div className="col-12">
                  <div className="feature-icon mb-4">
                  <div className="p-3 d-inline-flex bg-white rounded"> 
                    <FontAwesomeIcon icon={faBriefcaseMedical} className="fa-4x text-primary" />
                  </div>
                  </div>
                  <div className="feature-content d-flex flex-column">
                    <h5 className="mb-4">Personalized Treatment</h5>
                    <p className="mb-0">Tailored health strategies designed to fit your unique needs and achieve optimal results</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-6 col-lg-4 col-xl-3 wow fadeInUp" data-wow-delay="0.3s">
              <div className="row-cols-1 feature-item p-4">
                <div className="col-12">
                  <div className="feature-icon mb-4">
                    <div className="p-3 d-inline-flex bg-white rounded">
                      {/* <FontAwesomeIcon icon={faHospitalUser} className="fa-4x text-primary" /> */}
                      <FontAwesomeIcon icon={faBullseye} className="fa-4x text-primary" />
                    </div>
                  </div>
                  <div className="feature-content d-flex flex-column">
                    <h5 className="mb-4">Therapy Goals</h5>
                    <p className="mb-0">Setting clear, achievable targets to guide your recovery and track your progress effectively</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-6 col-lg-4 col-xl-3 wow fadeInUp" data-wow-delay="0.3s">
              <div className="row-cols-1 feature-item p-4">
                <div className="col-12">
                  <div className="feature-icon mb-4">
                    <div className="p-3 d-inline-flex bg-white rounded">
                      {/* <FontAwesomeIcon icon={faUsers} className="fa-4x text-primary" /> */}
                      <FontAwesomeIcon icon={faFileMedicalAlt} className="fa-4x text-primary" />
                    </div>
                  </div>
                  <div className="feature-content d-flex flex-column">
                    <h5 className="mb-4">Insurance Support</h5>
                    <p className="mb-0">Hassle-free insurance handling, making your path to recovery smooth and worry-free</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-6 col-lg-4 col-xl-3 wow fadeInUp" data-wow-delay="0.3s">
              <div className="row-cols-1 feature-item p-4">
                <div className="col-12">
                  <div className="feature-icon mb-4">
                    <div className="p-3 d-inline-flex bg-white rounded">
                      {/* <FontAwesomeIcon icon={faSpa} className="fa-4x text-primary" /> */}
                      <FontAwesomeIcon icon={faClinicMedical} className="fa-4x text-primary" />
                    </div>
                  </div>
                  <div className="feature-content d-flex flex-column">
                    <h5 className="mb-4">Modern Facility</h5>
                    <p className="mb-0">State-of-the-art amenities in a serene environment for unparalleled comfort and healing</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-6 col-lg-4 col-xl-3 wow fadeInUp" data-wow-delay="0.3s">
              <div className="row-cols-1 feature-item p-4">
                <div className="col-12">
                  <div className="feature-icon mb-4">
                    <div className="p-3 d-inline-flex bg-white rounded">
                      {/* <FontAwesomeIcon icon={faHeart} className="fa-4x text-primary" /> */}
                      <FontAwesomeIcon icon={faSmile} className="fa-4x text-primary" />
                    </div>
                  </div>
                  <div className="feature-content d-flex flex-column">
                    <h5 className="mb-4">Welcoming Staff</h5>
                    <p className="mb-0">Friendly professionals ready to support you with compassion and understanding at every step</p>
                  </div>
                </div>
              </div>
            </div>
            
            <div className="col-md-6 col-lg-4 col-xl-3 wow fadeInUp" data-wow-delay="0.3s">
              <div className="row-cols-1 feature-item p-4">
                <div className="col-12">
                  <div className="feature-icon mb-4">
                    <div className="p-3 d-inline-flex bg-white rounded">
                      {/* <FontAwesomeIcon icon={faPiedPiper} className="fa-4x text-primary" /> */}
                      <FontAwesomeIcon icon={faClock} className="fa-4x text-primary" />
                    </div>
                  </div>
                  <div className="feature-content d-flex flex-column">
                    <h5 className="mb-4">No Wait Times</h5>
                    <p className="mb-0">Efficient scheduling means your care begins promptly, valuing your time and wellbeing</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-6 col-lg-4 col-xl-3 wow fadeInUp" data-wow-delay="0.3s">
              <div className="row-cols-1 feature-item p-4">
                <div className="col-12">
                  <div className="feature-icon mb-4">
                    <div className="p-3 d-inline-flex bg-white rounded">
                      {/* <FontAwesomeIcon icon={faUserMd} className="fa-4x text-primary" /> */}
                      <FontAwesomeIcon icon={faHeartbeat} className="fa-4x text-primary" />
                    </div>
                  </div>
                  <div className="feature-content d-flex flex-column">
                    <h5 className="mb-4">Comprehensive Care</h5>
                    <p className="mb-0">A holistic approach that addresses all facets of your health for complete rejuvenation</p>
                  </div>
                </div>
              </div>
            </div>

          {/* Add other feature items following the same structure */}
        </div>
      </div>
    </div>
  );
};

export default Feature;
