import React from 'react';
import { Helmet } from 'react-helmet';

import Service1 from '../img/service-1.jpg';
import Service2 from '../img/service-2.jpg';
import Service3 from '../img/service-3.jpg';
import Service4 from '../img/service-4.jpg';
import Service5 from '../img/service-5.jpg';
import Service6 from '../img/service-6.jpg';
import Service7 from '../img/service-7.jpg';
import Service8 from '../img/service-8.jpg';
import PageTransitionWrapper from './PageTransitionWrapper';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom


const Services = () => {
  return (
    <PageTransitionWrapper>
      <Helmet>
        <title>Get On PhysicalTherapy - Professional Physical Therapy Services in NYC</title>
        <meta name="description" content="Explore our wide range of physical therapy services tailored to your individual needs. From massage therapy to post-surgical rehabilitation, our expert team in NYC is here to help. Specializing in sports injuries, work injuries, balance therapy, and more." />
      </Helmet>

      <div className="container-fluid service py-5" id="serviceSection">
        <div className="container py-5">
          <div className="section-title mb-5">
            <h1 className="display-3 mb-4">Our Service Given Physical Therapy By Experts</h1>
          </div>
          <div className="row g-4 justify-content-center">

            {/* Physical Therapy */}
            <div className="col-md-6 col-lg-4 col-xl-3">
              <div className="service-item rounded">
                <div className="service-img rounded-top">
                  <img src={Service2} className="img-fluid w-100" alt="Physical Therapy" />
                </div>
                <div className="service-content rounded-bottom bg-light p-4">
                  <h5 className="mb-4">Physical Therapy</h5>
                  <p>Customized treatment to improve mobility and alleviate pain.</p>
                  <Link to="/services/physical-therapy" className="btn btn-primary rounded-pill text-white py-2 px-4 mb-2" style={{ position: 'relative', zIndex: 10 }}>Read More</Link>
                </div>
              </div>
            </div>
      
            {/* Massage Therapy */}
            <div className="col-md-6 col-lg-4 col-xl-3">
              <div className="service-item rounded">
                <div className="service-img rounded-top">
                  <img src={Service1} className="img-fluid w-100" alt="Massage Therapy" />
                </div>
                <div className="service-content rounded-bottom bg-light p-4">
                  <h5 className="mb-4">Massage Therapy</h5>
                  <p>Tailored Massage sessions for stress relief and relaxation.</p>
                  {/* <Link to="/services/massage-therapy" className="btn btn-primary rounded-pill text-white py-2 px-4 mb-2" style={{ position: 'relative', zIndex: 10 }}>Read More</Link> */}
                </div>
              </div>
            </div>

            {/* Balance Therapy */}
            <div className="col-md-6 col-lg-4 col-xl-3">
              <div className="service-item rounded">
                <div className="service-img rounded-top">
                  <img src={Service3} className="img-fluid w-100" alt="Balance Therapy" />
                </div>
                <div className="service-content rounded-bottom bg-light p-4">
                  <h5 className="mb-4">Balance Therapy</h5>
                  <p>Enhanced stability and coordination for better mobility.</p>
                  {/* <Link to="/services/balance-therapy" className="btn btn-primary rounded-pill text-white py-2 px-4 mb-2" style={{ position: 'relative', zIndex: 10 }}>Read More</Link> */}
                </div>
              </div>
            </div>

            {/* Post-Surgical Training */}
            <div className="col-md-6 col-lg-4 col-xl-3">
              <div className="service-item rounded">
                <div className="service-img rounded-top">
                  <img src={Service4} className="img-fluid w-100" alt="Post-Surgical Training" />
                </div>
                <div className="service-content rounded-bottom bg-light p-4">
                  <h5 className="mb-4">Post-Surgical Training</h5>
                  <p>Focused rehabilitation to restore strength and function.</p>
                  {/* <Link to="/services/post-surgical-training" className="btn btn-primary rounded-pill text-white py-2 px-4 mb-2" style={{ position: 'relative', zIndex: 10 }}>Read More</Link> */}
                </div>
              </div>
            </div>

            {/* Gait Training */}
            <div className="col-md-6 col-lg-4 col-xl-3">
              <div className="service-item rounded">
                <div className="service-img rounded-top">
                  <img src={Service5} className="img-fluid w-100" alt="Gait Training" />
                </div>
                <div className="service-content rounded-bottom bg-light p-4">
                  <h5 className="mb-4">Gait Training</h5>
                  <p>Optimized walking patterns for improved movement.</p>
                  {/* <Link to="/services/gait-training" className="btn btn-primary rounded-pill text-white py-2 px-4 mb-2" style={{ position: 'relative', zIndex: 10 }}>Read More</Link> */}
                </div>
              </div>
            </div>

            {/* Work Injuries */}
            <div className="col-md-6 col-lg-4 col-xl-3">
              <div className="service-item rounded">
                <div className="service-img rounded-top">
                  <img src={Service6} className="img-fluid w-100" alt="Sports Injury Rehabilitation" />
                </div>
                <div className="service-content rounded-bottom bg-light p-4">
                  <h5 className="mb-4">Work Injuries</h5>
                  <p>Rehabilitation programs for work-related injury recovery.</p>
                  {/* <Link to="/services/work-injuries" className="btn btn-primary rounded-pill text-white py-2 px-4 mb-2" style={{ position: 'relative', zIndex: 10 }}>Read More</Link> */}
                </div>
              </div>
            </div>

            {/* Sports Injuries */}
            <div className="col-md-6 col-lg-4 col-xl-3">
              <div className="service-item rounded">
                <div className="service-img rounded-top">
                  <img src={Service7} className="img-fluid w-100" alt="Work Injury Rehabilitation" />
                </div>
                <div className="service-content rounded-bottom bg-light p-4">
                  <h5 className="mb-4">Sports Injuries</h5>
                  <p>Minimize downtime with rapid recovery for athletes.</p>
                  {/* <Link to="/services/sports-injuries" className="btn btn-primary rounded-pill text-white py-2 px-4 mb-2" style={{ position: 'relative', zIndex: 10 }}>Read More</Link> */}
                  </div>
              </div>
            </div>

            {/* Ongoing Strength Training */}
            <div className="col-md-6 col-lg-4 col-xl-3">
              <div className="service-item rounded">
                <div className="service-img rounded-top">
                  <img src={Service8} className="img-fluid w-100" alt="Custom Orthotics" />
                </div>
                <div className="service-content rounded-bottom bg-light p-4">
                  <h5 className="mb-4">Ongoing Strength Training</h5>
                  <p>Regular sessions to maintain health and prevent strains.</p>
                  {/* <Link to="/services/ongoing-strength-training" className="btn btn-primary rounded-pill text-white py-2 px-4 mb-2" style={{ position: 'relative', zIndex: 10 }}>Read More</Link> */}
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </PageTransitionWrapper>
  );
};

export default Services;
